* {
	margin: 0;
	padding: 0;

	&,
	&:before,
	&:after {
		box-sizing: inherit;
	}
}

::selection {
}
::-moz-selection {
}

// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in _config.scss
html {
	background: $light;
	box-sizing: border-box;
	color: $dark;
	font-family: $mainFont;
	font-size: 100% * ($baseFontSize / 16px);
	hyphens: manual;
	line-height: rem($baseLineHeight);

	/* disable text resize in landscape. e.g. on iphone */
	text-size-adjust: none;
}

body {
	line-height: rem($baseLineHeight);
}

iframe, [data-iframe] {
	border: none;
	width: 100%;

	&[data-src] {
		@extend %lazyloading;
	}
}


/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	@extend .marginBottom;
	display: block;
	text-rendering: optimizeLegibility;
	hyphens: auto;
	font-weight: 400;
	
	/* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
	*/
}

h1,
.h1 {
	font-family: $displayFont;
	font-size: rem(30px);
	line-height: 1.2em;
	color:$secondary;

	@include breakpoint(small) {
	font-size: rem($h1Size);		
	}
}

h2,
.h2 {
	font-size: rem($h2Size);
	font-family: $displayFont;
	line-height: 1.2em;
	color:$secondary;
}

h3,
.h3 {
	font-size: rem($h3Size);
	font-family: $displayFont;
	line-height: 1.2em;
	color:$secondary;
}

h4,
.h4 {
	font-size: rem($h4Size);
	font-family: $displayFont;
	line-height: 1.2em;
	color:$secondary;
}

h5,
.h5 {
	font-size: rem($h5Size);
	font-family: $displayFont;
	line-height: 1.2em;
	color:$secondary;
}

h6,
.h6 {
	font-size: rem($h6Size);
	font-family: $displayFont;
	line-height: 1.2em;
	color:$secondary;
}

/**
 * Links
 */
a {
	color: $primary;
	// word-wrap: break-word;
	transition: 0.3s;

	&:focus,
	&:hover,
	&:active {
		color: $secondary;
	}

	img {
		border: none;
	}

	&[href^="tel"] {
		color: inherit;
		text-decoration: underline;
	}
}

a:not(.btn):focus,
[tabindex]:focus {
	outline: $outline;
	outline-offset: rem(5px);
}

hr, .hr {
	display: flex;
	background: $border;
	border: none;
	clear: both;
	height: rem(2px);
	margin: 3rem 0;
	flex-basis: 100%;

	&.transparent {
		background: transparent;
		margin: 2rem 0;
	}
}

ul,
ol {
  list-style: none;  

	&:nth-of-type(1) {
		

		@include breakpoint(medium) {
		margin-bottom: 0;
		}

		@include breakpoint(large) {
		margin-bottom: rem(25px);		
		}
	}

  &.styledList {

    li {
      padding-left: rem(29px);
      margin-bottom: rem(7px);
      text-indent: rem(-6px);

      &:before {
      	font-family: $iconFont;
        content: map-get($iconMap, check-thin);
        text-indent: rem(-15px);
    	margin-left: rem(-20px);
    	padding-right: rem(10px);
    	color:$dark;
      }
    }

    ul {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
}

dl {
	&.styledList {
		@include pie-clearfix;

		dt,
		dd {
			display: block;
			background: rgba($medium, 0.1);
			padding: rem(25px);
		}

		dt {
			padding-bottom: 0;
			font-weight: 700;
		}

		dd {
			padding-top: 0;

			&:not(:last-child) {
				margin-bottom: rem(10px);
			}
		}
	}

	&.floatList {
		@include pie-clearfix;

		dt,
		dd {
			display: block;
			vertical-align: top;
			float: left;
		}

		dt {
			padding-right: 0.5rem;
			width: 40%;
			clear: left;
		}

		dd {
			width: 60%;
		}
	}
}

ol {
	list-style-type: none;
	counter-reset: item;

	li {
		padding-top: 0.3rem;
		padding-bottom: 0.3rem;
		display: table;
		counter-increment: item;

		&:before {
			content: counters(item, ".") ". ";
			display: table-cell;
			padding-right: 0.3em;
		}
	}

	ol {
		margin-bottom: 0;

		li {
			&:before {
				content: counters(item, ".") " ";
			}
		}
	}
}

/*
 * responsive images
 */

img {
	vertical-align: bottom;
	float: none;
	height: auto;
	max-width: 100%;
	width: 100%;

	&[src^='http://cms.'], &[src^='https://cms.'] {
		max-width: none;
		width: auto;
	}

	[data-rel] &,
	.lightbox-image & {
		margin-bottom: 0;
	}

	&[data-src] {
		@extend %lazyloading;
	}
}

/*
 * reset copyright link
 */
[href*="alpen-web.ch"],
[href*="bewertet.de"],
[href*="euroweb.at"],
[href*="euroweb.de"],
[href*="ew.de"],
[href*="geprueft.de"],
[href*="web2walk.com"],
[href*="webstyle.de"],
[href*="webstyle.com"],
[href*="wn-onlineservice.de"],
[href*="internet-media.com"],
[href*="yourrate.com"] {
	display: table;
	text-decoration: none;

	img {
		background: none;
		border-radius: 0;
		border: none;
		margin-bottom: rem(5px);
		outline: none;
		padding: 0;
		white-space: normal;
		width: auto !important;
	}
}

/**
 * Tables
 */

table {
  display: table;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  text-align: left;
  font-size: rem($baseFontSize);

  @include breakpoint(small, max) {
    overflow-x: auto;
    display: block;
  }

  caption {
    text-align: left;
    padding: rem($baseGap) rem($baseGap) rem(25px);
    display: table-caption;
    font-weight: 700;
    font-size: 1.2rem;
  }

  thead {
    border-collapse: collapse;
    display: table-header-group;
  }

  tbody {
    width: 100%;
    overflow-x: scroll;
    display: table-row-group;

    tr {
      padding: rem($baseGap) 0;

      &:not(:last-child) {
        border-bottom: rem(1px) solid $medium;
      }
    }
  }

  tr {
    display: table-row;
  }

  th,
  td {
    padding: rem($baseGap);
    display: table-cell;
    vertical-align: top;
  }

  @include breakpoint(small, max) {
    td {
      min-width: 50vw;
    }
  }
}
