// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

$desktopHover: false; // Set Hover to reach Submenus for Desktop (true, false)

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: rem(20px) rem(15px);
	text-decoration: none;
	color: $navColor;
}
%buttonHover {
	// Link Hover
	color: $primary;
	background-color: transparent;
}
%buttonActive {
	// Link Active
	color: $primary;
	background-color: transparent;
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, arrow-right);
	transform: rotateY(180deg);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, arrow-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 60px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;

		p.notdienstMobil {
		    line-height: 1.25rem;
		    padding: 1rem 1.5rem 1rem 2.5rem;
		    display: block;
		    height: auto;
		    background: $lila;
		    width: 100%;
		    color:$light;
		    position: absolute;
		    top:0;
			font-size: 14px;

			span {
				color:$primary;
				display: block;
				font-size: 20px;
		    	font-weight: bold;
			}			

				@include breakpoint(large) {
				display: none;	
				}
			}
	nav {

		p.notdienstDesktop {
		    line-height: 1.25rem;
		    display: none;
		    height: auto;
		    color:$light;
		    font-weight: bold;

			span {
				color:$primary;
				display: block;

				@include breakpoint(large) {
				display: inline-block;	
				}

				&.hide {
					color:$light;
					display: inline-block;
					padding-left: 5px;

					@include breakpoint(large) {
					display: none;	
					color:$dark;
					}

					@include breakpoint(huge) {
					display: inline-block;	
					}
				}
			}

			@include breakpoint(large) {
				width: 25%;
				padding: 2px 0 0 0;
				margin: 0;
				color:$dark;
				background: none;		
				font-size: rem(16px);
			    display: inline;
			    text-align: right;
    			float: left;
			}

			@include breakpoint(giant) {
				font-size: rem(18px);	
			    padding-left: rem(50px);
			}

			@include breakpoint(huge) {
				width: 35%;	
				font-size: rem(19px);	
			    padding-left: rem(30px);
			}

			@include breakpoint(full) {
				font-size: rem(20px);			
			}
		}
		

		@include ul {
			&.navi {
				padding: rem(20px) rem(50px) 0 rem(50px);

				li {
					@include navButtons {
						@include navButton;
						border-bottom: 2px dotted $border;
						
					}
					@include hasSub {
						@include sub {
							background: $navBg;
							padding: rem(20px) rem(50px) 0 rem(50px);

							&:before {
							    display: none;
							}
						}
					}
					@include navBack {
						width: 100%;
						color:$primary;
						background: transparent;
						padding: rem(5px) rem(20px);
						font-size: rem(14px);
    					margin-top: 80px;

    					@include breakpoint(large) {
    					margin-top: 0px;    						
    					}

						&:hover {
							color:$secondary;
						}

					}

					&.impressum, &.datenschutzerklaerung {
						display: none;
					}

					&.start {
						margin-top: rem(80px);

						@include breakpoint(large) {
						margin-top:0;	
						}
					}

				}
			}
		}
	}
}

// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#pageNavi {
		background: $light;
	    padding: rem(30px) rem(40px);
	    position: fixed;
	    top: rem(200px);

	    @include breakpoint(huge) {
	    padding: rem(30px) rem(60px);	    	
	    }

	    @include breakpoint(full) {
	    padding: rem(30px) rem(100px);	    	
	    }

	    &.fix {
		top: 140px;
	    padding: 1.5rem 2rem;

		    @include breakpoint(large) {
		     padding: 20px rem(40px); 
	    	border-bottom: 2px solid rgba($border,.4);	
		    }

		    @include breakpoint(huge) {
		    padding: 20px rem(60px);	    	
		    }

		    @include breakpoint(full) {
		    padding: 20px rem(100px);	    	
		    }
	    }

		nav {
			ul {
				margin-bottom: 0;

				@include breakpoint(large) {
			    float: left;
				width: 75%;					
				}

				@include breakpoint(huge) {
					width: 65%;				
				}

				&.navi {

					padding:0;

					li {
						padding:0;
						border:0;
						font-size: rem(17px);
						text-transform: uppercase;

						@include breakpoint(giant) {
						font-size: rem(18px);	
						}

						@include breakpoint(huge) {
						font-size: rem(19px);	
						}

						@include breakpoint(full) {
						font-size: rem(20px);	
						}

						a {

						padding:0;
						border:0;
						}
						
						&.hasSub {

							span:after {
								margin-left: 0.1rem;						
							}
							span {
							padding:0;
							border:0;
							}

							.sub {
								left: -50%;
								
								li{
								font-size: rem(18px);
								text-transform: none;
								padding: rem(5px) 0;

									&:nth-of-type(2) {
										padding-top:rem(20px);
									}

									 &:last-of-type {
										padding-bottom:rem(20px);
									}
								}
							}
						}

						&.nav-back {
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	@extend %animatedTransform;
	background-color: $primary;
	color: $light;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;

	p {
		font-size: rem(14px);
		font-weight: 700;
		margin-bottom: 0;

		@include breakpoint(small) {
		font-size: rem(18px);	
		}
	}

	@include breakpoint($break) {
		display: none;
	}
}
