// Relativ path to images folder (used in the image-url() function)
$imagePath: "../images/" !default;

// +++++ Font settings
// Font size of main content (in px)
$baseFontSize: 20px;

// Line height of main content (in px)
$baseLineHeight: 30px;

// Headline font sizes (in px)
$h1Size: 40px;
$h2Size: 30px;
$h3Size: 30px;
$h4Size: 25px;
$h5Size: 25px;
$h6Size: 25px;

// Font stacks: $mainFont for simple content, $displayFont for headlines etc., $iconFont for icons
$mainFont:    "Imprima", sans-serif;
$displayFont: "Gochi Hand", sans-serif;
$iconFont:   "icomoon";

// +++++ Design & Layout
// Colours for your design.
$primary:   #ee7502;
$secondary: #164352;
$third:     #016f94;
$light:     #fff;
$medium:    #bdbdbd;
$dark:      #6b7275;
$border:    #e5e5e5;
$alert:     #bbbb00;
$lila:      #701b76;
$lilaBorder:#a372a9;

// Horizontal padding left and right of grid columns
$baseGap: 18px;

// Grid row max-width
$rowMaxWidth: 1400px;

// Grid columns
$columnCount: 12;

// Standard border for your design
$baseBorder: 2px solid $border;

// Standart outline styles for focus states
$outline: 1px dotted $alert;

// Assume a different base font size for breakpoint em calculation (default: 16px)
$bpContext: 16px;

// +++++ Miscellaneous
// text-indent value when using hideText(): "right" = positive value, "left" = negative value
$hideTextDirection: "right";

$iconMap: (
    times: "×",
    minus: "-",
    angle-right: "❯",
    plus: "+",
    angle-up: "↑",
    exclamation: "!",

    check: "\e900",
    arrow-down: "\e901",
    arrow-left: "\e902",
    arrow-right: "\e903",
    mail: "\e904",
    phone: "\e905",
    check-circle: "\f05d",
    play: "\e906",
    check-thin: "\e908",
    pdf: "\e907"
);


/*  Grid Map 
    Define your breakpoints and grid classes below.
    Only generate the gridclasses you really need to reduce filesize.
    example:
    tiny: ( <-- "breakpoint name"
        width:  em(480px, $bpContext), <-- "breakpoint width"
        cols: (5,6,12), <-- "columns" result generates .tiny-5, .tiny-6 and .tiny-12
        prefix: (2,4), <-- "prefix" result generates .tiny-prefix-2 and .tiny-prefix-4
        suffix: (6,8), <-- "suffix" result generates .tiny-suffix-6 and .tiny-suffix-8
    )
*/
$gridMap: (
     default: (
        width:  em(320px, $bpContext),
         cols: (6)
    ),
    tiny: (
        width:  em(480px, $bpContext),
         cols: (6)
    ),
    small: (
        width:  em(640px, $bpContext),
        cols: (6)
    ),
    medium: (
        width:  em(800px, $bpContext),
        cols: (4,6,8,12)
    ),
    large: (
        width:  em(992px, $bpContext),
        cols: (3,4,5,7,8,6,12)
    ),
    giant: (
        width:  em(1200px, $bpContext),
        cols: (4,8)
    ),
    huge: (
        width:  em(1364px, $bpContext)
    ),
    full: (
        width:  em(1520px, $bpContext)
    )
);