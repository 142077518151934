.icon {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: $iconFont;
}

@each $name, $ico in $iconMap {
  // .icon-#{$name}
  .icon-#{$name}:before {
    content: "#{$ico}";
  }
}

.icon-facebook:before {
  content: "\e900";
  color: #3b5998;
}
.icon-envelope:before {
  content: "\e90b";
}
.icon-time:before {
  content: "\e90c";
}
.icon-chevron-left1:before {
  content: "\e901";
}
.icon-chevron-right1:before {
  content: "\e902";
}
.icon-arrow-outline-right:before {
  content: "\e903";
}
.icon-checkmark-outline:before {
  content: "\e904";
}
.icon-phone1:before {
  content: "\e942";
}
.icon-plus:before {
  content: "\f067";
}
.icon-minus:before {
  content: "\f068";
}
.icon-close:before {
  content: "\f00d";
}
.icon-remove:before {
  content: "\f00d";
}
.icon-times:before {
  content: "\f00d";
}
.icon-chevron-right:before {
  content: "\f054";
}
.icon-times-circle:before {
  content: "\f057";
}
.icon-exclamation-circle:before {
  content: "\f06a";
}
.icon-chevron-up:before {
  content: "\f077";
}
.icon-phone:before {
  content: "\f095";
}
.icon-bars:before {
  content: "\f0c9";
}
.icon-navicon:before {
  content: "\f0c9";
}
.icon-reorder:before {
  content: "\f0c9";
}
.icon-file-pdf-o:before {
  content: "\f1c1";
}
.icon-map-marker:before {
  content: "\e906";
}
.icon-chevron-left:before {
  content: "\f053";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}