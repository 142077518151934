$nav-height: calc(100vh - #{rem($barHeight)});

@mixin pageNavi($class: "#pageNavi") {
  #{$class} {
    @extend %animatedTransform;
    position: fixed;
    max-width: 100%;
    right: 0;
    @content;
  }
}
@mixin ul {
  ul {
    width: 100%;
    @content;
  }
}
@mixin navButtons {
  a,
  &:not(.nav-back) > span,
  &.nav-back,
  &.hasSub > span {
    @extend %animatedTransform;
    display: block;
    cursor: pointer;
    @content;

    &:hover {
      @extend %buttonHover;
    }
  }
  a,
  &.hasSub > span {
    &.active {
      @extend %buttonActive;
    }
  }
}
@mixin hasSub($class: ".hasSub") {
  &#{$class} {
    > span,
    > a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @content;

      &:after {
        @extend %nextIcon;
        margin-left: 0.5em;
        display: inline-block;
      }
    }
    &.active {
      > a,
      > span {
        @extend %buttonActive;
      }
    }
    @if $desktopHover == true {
      body:not(.touchdevice) & {
        @include breakpoint($break, max) {
          .sub.current {
            transform: translateX(0%);
          }
        }
        @include breakpoint($break) {
          &:hover {
            .sub {
              @include breakpoint($break) {
                opacity: 1;
                visibility: visible;
              }

              .hasSub:not(:hover) {
                .sub {
                  @include breakpoint($break) {
                    opacity: 0;
                    visibility: hidden;
                  }
                }
              }
            }
          }
        }
      }
    }
    @content;
  }
}
@mixin sub($class: ".sub") {
  #{$class} {
    @extend %animatedTransform;
    position: absolute;
    left: 0;
    top: 0;
    @content;

    @include breakpoint($break, max) {
      transform: translateX(100%);
    }
    @include breakpoint($break) {
      opacity: 0;
      visibility: hidden;
    }

    @if $desktopHover == true {
      body.touchdevice &.current {
        @include breakpoint($break, max) {
          transform: translateX(0%);
        }
        @include breakpoint($break) {
          opacity: 1;
          visibility: visible;
        }
      }
    } @else {
      &.current {
        @include breakpoint($break, max) {
          transform: translateX(0%);
        }
        @include breakpoint($break) {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
@mixin navBack {
  &.nav-back {
    @extend %animatedTransform;
    position: relative;
    @content;

    &:before {
      @extend %prevIcon;
      margin-right: 1em;
      display: inline-block;
    }
    &:hover {
      @extend %buttonHover;
    }
  }
}

@include breakpoint($break, max) {
  #pageNavi {
    min-width: 50%;
    overflow: hidden;

    @if $barPos == "top" {
      top: rem($barHeight);
      bottom: 0;
    } @else if $barPos == "bottom" {
      top: 0;
      bottom: rem($barHeight);
    }

    nav {
      ul {
        overflow: hidden;
        height: $nav-height;

        &.open,
        &.navi {
          overflow-y: auto;
          -webkit-overflow-scrolling: touch;
        }

        &.navi {
          li {
            &.hasSub {
              .sub {
                &:before {
                  content: attr(data-parent-name);
                }
              }
            }
          }
        }
      }
    }
  }
  html.hidden-scroll {
    overflow: hidden;
  }
}

@mixin desktopVersionFunctionality {
  #pageNavi {
    top: 0;
    width: 100%;

    nav {
      ul {
        &.navi {
          display: flex;
          justify-content: space-between;

          li {
            a,
            &:not(.nav-back) > span,
            .sub:before,
            .nav-back {
              white-space: nowrap;
            }
            &.hasSub {
              position: relative;

              .sub {
                top: 100%;
                width: auto;

                .sub {
                  left: 100%;
                  top: 0;
                }
              }
            }
            &.nav-back {
              @if $desktopHover == true {
                body:not(.touchdevice) & {
                  display: none;
                }
              }

              &:before {
                content: $closeIcon;
                text-align: center;
                width: 100%;
              }
              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

#quickbar {
  position: fixed;
  left: 0;
  width: 100%;

  @include breakpoint($break, max) {
    height: rem($barHeight);
  }

  @if $barPos == "top" {
    top: 0;

    @if $barHide {
      &.hide {
        transform: translateY(-100%);
      }
    }
  } @else if $barPos == "bottom" {
    bottom: 0;

    @if $barHide {
      &.hide {
        transform: translateY(100%);
      }
    }
  }

  label[for="naviToggled"] {
    display: block;
    cursor: pointer;
    width: rem($barHeight * 0.4);
    height: rem($barHeight * 0.4);
    position: relative;
    overflow: hidden;

    &:after {
      @extend %animatedTransform;
      content: "";
      position: fixed;
      left: 0;
      width: 200vw;
      height: 200vh;
      display: block;
      background-color: $dark;
      opacity: 0.9;
      z-index: -1;

      @if $barPos == "top" {
        top: rem($barHeight);
      } @else if $barPos == "bottom" {
        bottom: rem($barHeight);
      }
    }

    span {
      transition: 0.4s linear;
      text-indent: -9999px;
      width: 0;
      top: 50%;
      transform: translate(-50%, -50%);

      &,
      &:before,
      &:after {
        content: "";
        position: absolute;
        display: block;
        background: $barColor;
        height: rem(2px);
        left: 50%;
        transform-origin: center;
      }
      &:before,
      &:after {
        top: calc(50% + 0rem);
        width: rem($barHeight * 0.4);
        transition: top 0.3s ease-in-out, transform 0.3s 0.2s linear;
      }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}

#naviToggled {
  display: none;

  &:not(:checked) {
    ~ * #pageNavi,
    ~ #pageNavi {
      @include breakpoint($break, max) {
        transform: translateX(100%);
      }
    }
    ~ #quickbar {
      label[for="naviToggled"] {
        &:after {
          opacity: 0;
          visibility: hidden;
        }
        span {
          width: rem($barHeight * 0.4);

          &:before,
          &:after {
            transform: translate(-50%, -50%);
            transition: top 0.3s 0.2s ease-in-out, transform 0.3s linear;
          }
          &:before {
            top: calc(50% + -#{rem(($barHeight * 0.4) * 0.25)});
          }
          &:after {
            top: calc(50% + #{rem(($barHeight * 0.4) * 0.25)});
          }
        }
      }
    }
  }
}
