// if you want to use more file types, write: $type: woff ttf woff2 (no comma separation!)

@include font-face(
 $name: "icomoon",
 $file: "icomoon",
 $weight: 400,
 $style: normal,
 $type: woff
);
@font-face {
  font-family: 'Gochi Hand';
  font-style: normal;
  font-weight: 400;
  src: local('Gochi Hand'), local('GochiHand-Regular'), url(/_fgf/s/gochihand/v9/hES06XlsOjtJsgCkx1Pkfon_-w.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
} 


@font-face {
  font-family: 'Imprima';
  font-style: normal;
  font-weight: 400;
  src: local('Imprima'), local('Imprima-Regular'), url(/_fgf/s/imprima/v7/VEMxRoN7sY3yuy-7yoKNyQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
} 
@font-face {
  font-family: 'Gochi Hand';
  font-style: normal;
  font-weight: 400;
  src: local('Gochi Hand'), local('GochiHand-Regular'), url(/_fgf/s/gochihand/v9/hES06XlsOjtJsgCkx1Pkfon__Q.woff) format('woff');
}
@font-face {
  font-family: 'Imprima';
  font-style: normal;
  font-weight: 400;
  src: local('Imprima'), local('Imprima-Regular'), url(/_fgf/s/imprima/v7/VEMxRoN7sY3yuy-7yoKNzw.woff) format('woff');
}
@font-face {
  font-family: 'Gochi Hand';
  font-style: normal;
  font-weight: 400;
  src: local('Gochi Hand'), local('GochiHand-Regular'), url(/_fgf/s/gochihand/v9/hES06XlsOjtJsgCkx1Pkfon__g.ttf) format('truetype');
}
@font-face {
  font-family: 'Imprima';
  font-style: normal;
  font-weight: 400;
  src: local('Imprima'), local('Imprima-Regular'), url(/_fgf/s/imprima/v7/VEMxRoN7sY3yuy-7yoKNzA.ttf) format('truetype');
}
