

// HEADER
//////////////////////////////

#pageHeader {
	position: relative;
	padding: 0;
	width: 100%;
	background: transparent;
    margin-top: rem(60px);
    overflow: hidden;

    @include breakpoint(large) {
    margin-top: rem(290px);     	
    }

	&.lazyLoaded {
		background-size:cover
	}

	@include breakpoint(medium) {
		padding:0;
	}

	picture {
		// position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 0;
		width: 100%;
		height: auto;
	}

	.praxenContainer {
		padding: rem(25px) 0;
		font-size: rem(17px);
		line-height: rem(24px);
		background: #f6f6f6;
    	

		@include breakpoint(large) {
		position: fixed;
		border-top:10px solid #bebd00; 
	    top: 0;
	    z-index: 25;
	    background: #f1f1f1;
	    width: 100%;	
		}

		.row.border {
			margin-top: -8px;
			.socialLink{
				a {
					margin-right: rem(10px);
					margin-top: rem(10px);
					&.facebook {
					background: url(../images/layout/facebook.png) no-repeat center center;
					text-indent: -99999px;
					display: inline-block;
					width: rem(39px);
					height: rem(39px);
					position: relative;
					top:-2px;
					left: 13px;
					}

					&.instagram {
					background: url(../images/layout/instagram.png) no-repeat center center;
					text-indent: -99999px;
					display: inline-block;
					width: rem(40px);
					height: rem(40px);
					position: relative;
					top:rem(-2px);
					left: rem(15px);
					}
					&:hover {
						opacity: .7;
					}
				}
			}
		}

		.branding {
			display: block;
			max-width: 200px;
			width: 100%;
			height: auto;
			margin: 0 auto;
			position: relative;
			z-index: 1;
    		padding-bottom: 20px;


			@include breakpoint(medium) {
			width: auto;
			margin: 0;	
    		padding-bottom: 0px;		
			}

			&:hover {
				opacity: .6;
			}

			&.left {
				@include breakpoint(medium) {
				float: right;	
				}
			}

			&.right {
				@include breakpoint(medium) {
				float: left;	
				}
			}
		}

		p {
			text-align: center;

			&.first {
				@include breakpoint(medium) {
				text-align: right;
				position: relative;	
				top: rem(70px);
    			right: rem(18px);	
				}
			}

			&.last {
				@include breakpoint(medium) {
				text-align: left;
				position: relative;	
				top: rem(70px);
    			left: rem(18px);
				}
			}

			span {
				color:$primary;
				text-transform: uppercase;

				&.tel {				
				color:$dark;
				text-transform: none;
				display: block;
				font-size: rem(15px);

				@include breakpoint(tiny) {
				font-size: 1rem;	
				}

				@include breakpoint(medium) {					
				font-size: rem(18px);
				}

				@include breakpoint(large) {
				font-size: 1rem;	
				}

					&:before {
						font-family: $iconFont;
						content: map-get($iconMap, phone);
						color:$dark;
						padding-right: rem(3px);
						font-size: rem(15px);
						position: relative;
    					top: 2px;

    					@include breakpoint(tiny) {
    					font-size: 1rem;	
						padding-right: rem(5px);	
    					}

						@include breakpoint(medium) {					
						font-size: rem(18px);
						}

						@include breakpoint(large) {
						font-size: 1rem;	
						padding-right: rem(10px);	
						}
					}					
				}

				&.email {
				color:$dark;
				text-transform: none;
				display: block;

					&:before {
						font-family: $iconFont;
						content: map-get($iconMap, mail);
						padding-right: rem(5px);
						color:$dark;
					}					
				}
			}

			a {
				color:#8d8d8d;
				text-decoration: none;

				&:hover {
					color:$third;
				}
			}
		}

	}
	
	div#praxenContainer.fix1 {
		.branding {
			max-width: 120px;
		}

		p {
			top:5px;
		}
	}


	.sliderContainer {
			position: relative;
			z-index: 1;
			width: 100%;

			body:not(.index) & {
			display: none;

				@include breakpoint(medium) {
				display: block;	
				}
			}

			body.cmsBackend & {
				display: none;
			}

			button {
				display: none;
			}

			.my-slider {
				> div {
					&:nth-of-type(2),&:nth-of-type(3) {
						display: none;
					}
				}

				&.tns-slider  {
					> div {
						&:nth-of-type(2),&:nth-of-type(3) {
							display: inline-block;
						}	
					}
				}

			.lachender-junge {
				background: url(../images/layout/slider/tiny-lachender-junge.jpg) no-repeat center left;
				padding:70px 0;

				@include breakpoint(medium) {
				background: url(../images/layout/slider/medium-lachender-junge.jpg) no-repeat center left;
				padding:130px 0;

					body:not(.index) & {
					padding:70px 0;
					}					
				}

				@include breakpoint(giant) {
				background: url(../images/layout/slider/full-lachender-junge.jpg) no-repeat center /cover;
				padding:170px 0;	

					body:not(.index) & {
					padding:100px 0;
					}																						
				}

				@include breakpoint(huge) {
				background: url(../images/layout/slider/lachender-junge.jpg) no-repeat center /cover;	
				padding:210px 0;	

					body:not(.index) & {
					padding:100px 0;
					}																								
				}
			}

			.lachende-frau {
				background: url(../images/layout/slider/tiny-lachende-frau.jpg) no-repeat center left;
				padding:70px 0;

				@include breakpoint(medium) {
				background: url(../images/layout/slider/medium-lachende-frau.jpg) no-repeat center left;
				padding:115px 0;	

					body:not(.index) & {
					padding:70px 0;
					}													
				}

				@include breakpoint(giant) {
				background: url(../images/layout/slider/full-lachende-frau.jpg) no-repeat center /cover;	
				padding:170px 0;

					body:not(.index) & {
					padding:100px 0;
					}																		
				}

				@include breakpoint(huge) {
				background: url(../images/layout/slider/lachende-frau.jpg) no-repeat center /cover;
				padding:210px 0;	

					body:not(.index) & {
					padding:100px 0;
					}																					
				}
			}


			.lachender-mann {
				background: url(../images/layout/slider/tiny-lachender-mann.jpg) no-repeat center left;
				padding:70px 0;

				@include breakpoint(medium) {
				background: url(../images/layout/slider/medium-lachender-mann.jpg) no-repeat center left;	
				padding:130px 0;	

					body:not(.index) & {
					padding:70px 0;
					}												
				}

				@include breakpoint(giant) {
				background: url(../images/layout/slider/full-lachender-mann.jpg) no-repeat center /cover;	
				padding:170px 0;	

					body:not(.index) & {
					padding:100px 0;
					}																					
				}

				@include breakpoint(huge) {
				background: url(../images/layout/slider/lachender-mann.jpg) no-repeat center /cover;	
				padding:210px 0;	

					body:not(.index) & {
					padding:100px 0;
					}																									
				}
			}
		}

			div.tns-controls {
				display: none;
			}

		blockquote {
			z-index: 10;
			background: none;
		    text-align: center;
		    padding: 0 rem(25px);

		    @include breakpoint(small) {
		    padding: 0 rem(40px) 0 rem(150px);	
		    }

		    @include breakpoint(medium) {
		    padding: 0 rem(40px) 0 rem(300px);			    	
		    }

		    @include breakpoint(huge) {
		    padding: 0 rem(40px) 0 rem(580px);			    	
		    }

			p {
				margin-bottom: rem(20px);
				// max-width: 650px;

				br {
					display: none;

					@include breakpoint(large) {
					display: block;	
					}
				}

				&.headline {
					color: $secondary;
					font-size: 35px;
					line-height: rem(35px);
					font-family: $displayFont;

					@include breakpoint(medium) {
					font-size: rem(55px);
					line-height: rem(55px);						

						body:not(.index) & {
						font-size: rem(35px);
						line-height: rem(35px);	
						}			
					}

					@include breakpoint(giant) {
					font-size: rem(70px);
					line-height: rem(70px);							

						body:not(.index) & {
						font-size: rem(35px);
						line-height: rem(35px);	
						}			
					}

					br {
						display: none;

						@include breakpoint(large) {
						display: block;

							body:not(.index) & {
								display: none;
							}	
						}
					}
				}

				font-size: rem(16px);
				line-height: rem(20px);
				color:$dark;

				@include breakpoint(medium) {
				font-size: rem(20px);
				line-height: rem(28px);	
				margin-bottom: rem(25px);				
				}

				@include breakpoint(giant) {
				font-size: rem(23px);
				line-height: rem(31px);	
					
				}
			}

			a.btn {
				font-size: rem(16px);
				line-height: rem(23px);
				display: inline-block;
			}
		}

	}

}

section {
	&.padding {
		padding:rem(50px) 0;

		@include breakpoint(medium) {
		padding: rem(75px) 0;	
		}

		@include breakpoint(giant) {
		padding: rem(100px) 0;				
		}

		@include breakpoint(huge) {
		padding: rem(130px) 0;				
		}
	}

	&.bgFirst {
		background: none;

		@include breakpoint(large) {	
		background: url(../images/index/gesundes-lachen-erwachsen.jpg) no-repeat bottom fixed;
		background-size: cover;

			body.internetExplorer &, body.edge & {
				background:#f7f7f7 url(../images/index/gesundes-lachen-erwachsen.jpg) no-repeat center center;
			}
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;

	a.pdf {
		&:before {
			font-family: $iconFont;
        	content: map-get($iconMap, pdf);
        	display: inline-block;
        	color:$secondary;
        	text-decoration: none;
        	padding-right: rem(10px);
		}
	}

	body.gronauTeam &, body.vredenTeam &  {
		p.h4 {
			margin-bottom: 0;
			font-size: rem(23px);
			hyphens:none;
		}

		.teamBottom {
			margin-bottom: rem(50px);
		}
	}

		
		.video-cont {
			width: 100%;
			background: url(../images/index/tiny-video-background.jpg) no-repeat bottom fixed;
			background-size: cover;
			height: 600px;

			@include breakpoint(tiny) {
			background: url(../images/index/medium-video-background.jpg)no-repeat bottom fixed;
			background-size: cover;	
			}

			@include breakpoint(medium) {
			background: url(../images/index/giant-video-background.jpg) no-repeat bottom fixed;
			background-size: cover;	
			}

			@include breakpoint(giant) {
			background: url(../images/index/video-background.jpg) no-repeat bottom fixed;
			background-size: cover;	
			}

			body.internetExplorer &, body.edge & {
				background:$primary url(../images/index/tiny-video-background.jpg) no-repeat center top;

				@include breakpoint(tiny) {
				background:$primary url(../images/index/medium-video-background.jpg) no-repeat center top;
				}

				@include breakpoint(medium) {
				background:$primary url(../images/index/giant-video-background.jpg) no-repeat center top;
				}

				@include breakpoint(giant) {
				background:$primary url(../images/index/video-background.jpg) no-repeat center center;
				}
			}

		
		// >.row {
		// 	justify-content: center;
		// 	z-index: 99999;
		// }

		.video {
			position: fixed;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: block;
			z-index: 999999;
			width: 100%;
			display: none;

			////// CLOSE BUTTON /////

			label[for="video"] {
				position: fixed;
				top: 2rem;
				right: 2rem;
				color: $light;
				background: $primary;
				padding:20px;
				font-size: rem(20px);
			}
			///////////

			.video-box {
				height: 100%;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 1rem; 	

				iframe {
					height: 90vH;
					
					@include breakpoint(custom) {
						width: 80%;
					}
				}
			}
		}

		label[for="video"] {
			display: flex;
			cursor: pointer;
			opacity: 1;
			align-items: center;
			transition: 0.3s;
			justify-content: center;

			span {
				background: transparent;
				transition: 0.3s;

				&:before {
					font-family: $iconFont;
					content: map-get($iconMap, play);
					color:$light;
					top: 230px;
					left: 40%;
					// transform: translate(-50%, -50%);
					z-index: 0;
					// width: 90%;
					height: auto;
				    font-size: 100px;
				    position: absolute;

				    @include breakpoint(tiny) {
					left: 45%;			    	
				    }

				    @include breakpoint(giant) {
					left: 47%;			    	
				    }
				}
			}

			&:hover {
				opacity: 0.7;
				transition: 0.3s;
				
				span {

					&:before {
						transform: scale(1.2);
						transition: 0.3s;
					}
				}

				&:before {
					color: $secondary;
				}
			}

			&:after {
				@extend %animatedTransform;
				content: "";
				position: fixed;
				left: 0;
				top: 0;
				width: 200vw;
				height: 200vh;
				display: block;
				opacity: 0;
				visibility: hidden;
				z-index: 99999;
			}
		}
	}

	#video {
		display: none;

			&:checked {
			~ * .video,	~ .video {
				display: block;
			}

			~ label[for="video"] {
				&:after {
					opacity: 0.9;
					visibility: visible;
					pointer-events: auto;
				}

				&:before {
					opacity: 1;
					visibility: visible;
					display: block;
				}
			}
		}
	}




	// .uvContainer {
	// 	width: 100%;
	// 	background: url(../images/index/tiny-video-background.jpg) no-repeat 50% fixed / cover;
	// 	// padding: rem(50px) 0;
	// 	height: 600px;

	// 	@include breakpoint(tiny) {
	// 	background: url(../images/index/medium-video-background.jpg) no-repeat 50% fixed / cover;	
	// 	}

	// 	@include breakpoint(medium) {
	// 	background: url(../images/index/giant-video-background.jpg) no-repeat 50% fixed / cover;	
	// 	}

	// 	@include breakpoint(giant) {
	// 	background: url(../images/index/video-background.jpg) no-repeat 50% fixed / cover;	
	// 	}


	// 	a {
	// 		width: 100%;
	// 		height: 100%;
	// 		text-decoration: none;

	// 		&:hover {
	// 		}

	// 		&:before {
	// 			font-family: $iconFont;
	// 			content: map-get($iconMap, play);
	// 			color:$light;
	// 			top: 50%;
	// 			left: 40%;
	// 			transform: translate(-50%, -50%);
	// 			z-index: 0;
	// 			width: 90%;
	// 			height: auto;
	// 		    font-size: 100px;
	// 		    position: relative;

	// 		    @include breakpoint(tiny) {
	// 			left: 45%;			    	
	// 		    }
	// 		}
	// 	}
	// }

	.kreisTestimonial {
		border-radius: 100%;
		background: $lila;
		border:10px solid $lilaBorder;
		width: 300px;
		height: 300px;
		padding: rem(40px);
		text-align: center;
		margin-top: rem(25px);
	    margin-left: -150px;
	    left: 50%;
	    position: relative;

		@include breakpoint(giant) {
		border:15px solid $lilaBorder;
		width: 380px;
		height: 390px;
		margin-top: 0;
		padding: rem(50px);	
	    margin-left: inherit;
	    left: 0%;
	    position: relative;		
		}

		@include breakpoint(huge) {
		width: 390px;		
		}

		&:before {
			color:rgba($light,.2);
			content: '"';
			display: block;
			position: absolute;
			top:rem(120px);
			left: rem(30px);
			font-size: rem(110px);

			@include breakpoint(giant) {
			top:rem(160px);
			left: rem(40px);
			font-size: rem(140px);				
			}
		}

		&:after {
			color:rgba($light,.2);
			content: '"';
			display: block;
			position: absolute;
			bottom:rem(15px);
			right: rem(60px);
			font-size: rem(110px);

			@include breakpoint(giant) {			
			bottom:rem(15px);
			right: rem(80px);
			font-size: rem(140px);	
			}
		}



		p {
			color:rgba($light,.8);
			font-size: rem(13px);
			line-height: rem(18px);
			margin-bottom: 0;

			@include breakpoint(giant) {
			font-size: rem(17px);
			line-height: rem(23px);				
			}

			&.headline {
				color:$light;
				font-size: bold;
				font-size: rem(15px);
				line-height: rem(20px);
				margin-bottom: rem(10px);
				margin-top: rem(10px);

				@include breakpoint(giant) {
					font-size: rem(21px);
					line-height: rem(26px);	
					margin-top: rem(15px);				
				}
			}
		}
	}

	.kreisHighlights {
		border-radius: 100%;
		background: $primary;
		border:10px solid #f7b377;
		width: 300px;
		height: 300px;
		padding: rem(40px);
		text-align: center;
		margin-bottom: rem(25px);
	    margin-left: -150px;
	    left: 50%;
	    position: relative;

			@include breakpoint(giant) {
			border:15px solid #f7b377;
			width: 390px;
			height: 390px;
			margin-bottom: 0;
			padding: rem(50px);	
		    margin-left: inherit;
		    left: 0%;
		    position: relative;		
			}

			ul {

				li {
					color:rgba($light,.8);
					font-size: rem(18px);
					line-height: rem(27px);
					margin-bottom: 0;

					@include breakpoint(giant) {
					font-size: rem(23px);
					line-height: rem(34px);			
					}

					&:before {
						color:$light !important;
						font-weight: 400;
					}
				}
			}
		}

	.padding-large {
		@include breakpoint(large) {
		padding-left: rem(50px);	
		}
	}

	.beratungContainer {
		width: 100%;
		background: #f6f6f6;
		padding: rem(100px) 0;
		text-align: center;

		@include breakpoint(giant) {
		padding: rem(130px) 0;			
		}

		p {
			color:#8d9395;
			margin-bottom: 0;
			font-size: rem(15px);
			line-height: rem(22px);

			@include breakpoint(medium) {
			font-size: rem(22px);
			line-height: rem(32px);				
			}

			&.headline {
				font-family: $displayFont;
				color:$secondary;
				font-size: rem(35px);
				line-height: rem(40px);
				margin-bottom: rem(20px);

				@include breakpoint(medium) {
				font-size: rem(40px);
				line-height: rem(40px);					
				}

				@include breakpoint(giant) {
				font-size: rem(60px);
				line-height: rem(60px);					
				}
			}
		}
	}

	p {
		&.borderLeft {
			border-left: 2px dotted $medium;
			margin-bottom: 0;
			padding-left: rem(30px); 
		}
	}




	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList) {
		@extend .styledList;
	}

	.googleMaps {
		// @extend .videoContainer;

		@include breakpoint(medium) {
			height: rem(400px);
		}

		&.content {
			height: rem(600px);
		}
	}
}

	.modalGallery a img {
		margin-bottom: rem(30px);
	}

	body.kontakt  {
			p {
				
			span {
			&.headline {
				text-transform: uppercase;
				color:$primary;
				display: block;
				font-weight: bold;
				}
			}
		}
	}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}


}

.noMarginBottom {
	margin-bottom: 0;
}

.marginTop {
	margin-top: rem(50px);
}

// FOOTER
//////////////////////////////

footer {

	#pageFooter {
		background: #f6f6f6;
		padding: rem(50px) 0 ;

		@include breakpoint(medium) {	
		padding: rem(75px) 0 ;	
		}

		@include breakpoint(giant) {	
		padding: rem(100px) 0 ;	
		}

		@include breakpoint(huge) {	
		padding: rem(130px) 0 ;	
		}
		.orderContainer {

			body.vreden & {
			display: flex;
			flex-direction: row-reverse;

				.orderFirst {
				order:3;
				}

				.order {
				order:1;
				}

				.hr {
					order:2;
				}
			}

		}




		body.cmsBackend & {
			display: none;
		}

		p {
			font-size: rem(18px);
			line-height: rem(27px);

			@include breakpoint(tiny) {
			font-size:rem(20px);	
			line-height: rem(30px);
			}
			span {
			&.headline {
				text-transform: uppercase;
				color:$primary;
				display: block;
				font-weight: bold;
				}
			}
		}



		dl {
			margin-top: rem(20px);
			font-size: rem(18px);
			line-height: rem(27px);
			
			@include breakpoint(tiny) {
			font-size:rem(20px);	
			line-height: rem(30px);
			}
			
			dt {
				display: inline-block;
				// padding-right: rem(20px);
				width: 30%;
	    		vertical-align: top;
	    		// font-weight: bold;
			}

			dd {
				display: inline-block;
				width: 70%;

				br {
					display: block;

					@include breakpoint(small) {
					display: none;	
					}

					@include breakpoint(large) {
					display: block;	
					}

					@include breakpoint(giant) {
					display: none;	
					}
				}
			}


			a {
				color:$medium;
				text-decoration: none;

				&:hover {
					color:$primary;
				}
			}
		}

		iframe.googleMaps {
			height: rem(370px);
			border:4px solid $light;
			width: 100%;
			display: block;
		}


		ul.socialLinks {
			position: relative;
			margin-bottom: 50px;

			li {
				display: inline-block;

				&.jameda {
					display: block;
					margin: rem(50px) 0 rem(25px);

					@include breakpoint(tiny) {
					display: inline-block;	
					margin: rem(25px) 0;
					}
				}

				a {
					margin-right: rem(10px);

					&.facebook {
					background: url(../images/layout/facebook.png) no-repeat center center;
					text-indent: -99999px;
					display: inline-block;
					width: rem(39px);
					height: rem(39px);
					position: relative;
					top:-2px;
					left: 13px;
					}

					&.instagram {
					background: url(../images/layout/instagram.png) no-repeat center center;
					text-indent: -99999px;
					display: inline-block;
					width: rem(40px);
					height: rem(40px);
					position: relative;
					top:rem(-2px);
					left: rem(15px);
					}

					// &.youtube {
					// background: url(../images/layout/youtube.svg);
					// text-indent: -99999px;
					// display: inline-block;
					// width: 50px;
					// height: 50px;
					// position: relative;
					// top:21px;
					// left: 13px;
					// }

					&:hover {
						opacity: .7;
					}
				}

				
			}
		}
		.order {
			display: flex;
			flex-flow: row wrap;

			@include breakpoint(large) {
			display: block;	
			}

			.switch:nth-of-type(1) {
				order:2;

				@include breakpoint(large) {
				order: 1;	
				}
			}
			.switch:nth-of-type(2) {
				order:1;

				@include breakpoint(large) {
				order: 2;	
				}
			}
		}

	}

	.navi {
		width: 100%;
		background: $light;
		text-align: center;
		padding: rem(25px);
		
		@include breakpoint(small) {
		justify-content: center;
		display: flex;	
		padding: rem(50px) 0;		
		}

		li {
			margin: 0 1rem;
			display: block;

			a  {
				text-transform: uppercase;
				color:$secondary;
				font-size: rem(18px);
				text-decoration: none;

				&.active, &:hover {
					color:$primary;
				}
			}
		}
	}
}

//Popup
.popup-row{
	position: fixed;
	z-index: 100000;
	margin: 0 auto;
	height: 100%;
	width: 100%;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	}

	.popup {
	background-color: white;
	padding: 1.5rem;
	width: 300px;
	height: 300px;
	border-radius:50%;
	position: relative;
	display: flex;
    align-items: center;
    justify-content: center;
	flex-direction: column;
	border: 2px solid $primary;
		p{
			font-size: 0.5rem;
			line-height: 1.5rem;
			font-size: 1rem;
			text-align: center;
		}
		.btn{
			font-size: 0.8rem;
			padding: 0.5rem;
		}
	@include breakpoint(tiny) {
		width: 400px;
		height: 400px;
		p{
			font-size: 1rem;
			line-height: 2rem;
			font-size: 1.2rem;
			text-align: center;
		}
		.btn{
			font-size: 1.2rem;
			padding: 1rem;
		}
	}

	@include breakpoint(medium) {
		width: 450px;
		height: 450px;
		p{
			font-size: 1.2rem;
			line-height: 2rem;
		}
	}
}
//End Popup
